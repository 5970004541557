import Vue from 'vue'
import Vuex from 'vuex'
import pages from "./modules/pages";
import popups from "./modules/popups";
import emails from "./modules/emails";
import galleries from "./modules/galleries";
import histories from "./modules/histories";
import funnels from "./modules/funnels";
import themes from "./modules/themes";
import blocks from "./modules/blocks";
import loader from "./modules/loader";
import pdfs from "./modules/pdfs";
import ai from "./modules/ai";
import live from "./modules/live";
import wizywig from "./modules/wizywig";
import automation from "./modules/automation";
Vue.use(Vuex, Vue.$http);

// Store functionality
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

// Create a new store
const store = new Vuex.Store({
  actions: actions,
  getters: getters,
  mutations: mutations,
  state: state,
  modules: {
    galleries,
    pages,
    popups,
    emails,
    histories,
    funnels,
    themes,
    blocks,
    loader,
    pdfs,
    ai,
    live,
    wizywig,
    automation
  }
});

export default store
