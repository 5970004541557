export default {
    emailLists: {
        list: '/business/campaign-email-lists',
        detail: (id) => `/business/campaign-email-lists/${id}/`,
        create: '/business/campaign-email-lists/',
        update: (id) => `/business/campaign-email-lists/${id}/`,
        delete: (id) => `/business/campaign-email-lists/${id}`,
    },
    automations: {
        list: '/business/email-automations',
        detail: (id) => `/business/email-automations/${id}/`,
        create: '/business/email-automations/',
        update: (id) => `/business/email-automations/${id}/`,
        delete: (id) => `/business/email-automations/${id}`,
    },
    automationSteps: {
        list: '/business/email-automation-steps',
        detail: (id) => `/business/email-automation-steps/${id}/`,
        create: '/business/email-automation-steps/',
        update: (id) => `/business/email-automation-steps/${id}/`,
        delete: (id) => `/business/email-automation-steps/${id}`,
    },
    automationLogs: {
        list: '/business/email-automation-logs',
        detail: (id) => `/business/email-automation-logs/${id}/`,
        create: '/business/email-automation-logs/',
        update: (id) => `/business/email-automation-logs/${id}/`,
        delete: (id) => `/business/email-automation-logs/${id}`,
    },
};